import React, { useContext, useMemo } from 'react';
import {
  Chain,
  ConnectWalletButtonV2Wrapper,
  DoubleThemeContext,
  NetworkButtonWrapper,
  TopBarV3,
} from 'common-client';
import { menuConfig } from 'config';
import { LogoWrapper } from 'wrappers/LogoWrapper';
import { NotificationBarWrapper } from './NotificationBarWrapper';

const routeList = [
  {
    href: '/buy',
    label: 'Buy',
  },
  {
    href: '/sell',
    label: 'Sell',
  },
  {
    href: '/holdings',
    label: 'Holdings',
  },
  {
    href: '/orders',
    label: 'Orders',
  },
];

const menuItems = menuConfig.map(({
  icon, name, url, node,
}) => ({
  label: name,
  path: url,
  image: icon,
  node,
}));

export function TopBarWrapper() {
  const { theme, toggleTheme } = useContext(DoubleThemeContext);
  useMemo(() => <NotificationBarWrapper />, []);

  return (
    <>
      <TopBarV3
        logo={<LogoWrapper />}
        menuItems={menuItems}
        routeList={routeList}
        connectWalletButton={<ConnectWalletButtonV2Wrapper />}
        networkButton={
          <NetworkButtonWrapper availableChains={[Chain.Mainnet]} testChains={[Chain.Goerli]} />
      }
        onThemeSwitch={toggleTheme}
        isDarkModeEnabled={theme.palette.mode === 'dark'}
        // notificationBar={notificationBar}
      />
    </>
  );
}
