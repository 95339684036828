import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'index.css';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import '@fontsource/inter';
import '@fontsource/inter/800.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/500.css';
import '@fontsource/space-mono';
import { CssBaseline } from '@mui/material';
import { DoubleThemeProvider } from 'common-client';
import { darkTheme, lightTheme } from './theme.locked';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://e446a74ea65647d3bcabfa669b8a4aea@o1058440.ingest.sentry.io/6046105',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <DoubleThemeProvider lightTheme={lightTheme} darkTheme={darkTheme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <App />
  </DoubleThemeProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
