import { NotificationBar } from 'components/NotificationBar';
import React, { useEffect, useState } from 'react';

export function NotificationBarWrapper() {
  const [deployDate, setDeployDate] = useState<Date | null>(null);

  useEffect(() => {
    const now: Date = new Date();
    const nextDeployDate: Date = new Date();

    nextDeployDate.setMonth(now.getMonth() + 1);
    nextDeployDate.setDate(1);
    nextDeployDate.setHours(0, 0, 0, 0);
    setDeployDate(nextDeployDate);
  }, []);

  return deployDate && <NotificationBar deployDate={deployDate} />;
}
