import React, {
  createContext, ReactNode, useContext, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { AppTour } from 'components/AppTour';
import { WalletConnectionContext } from 'common-client';
import { Step } from 'react-joyride';

export interface TourConfigStep extends Step {
  /**
   * The path associated with the step
   */
  path: string,
  /**
   * If the step is in requires the user to have their wallet connected
   */
  requiresWalletConnection: boolean,
  /**
   * Timeout in milliseconds so that the current step can be rendered
   */
  timeoutMs: number,
}

interface TourObject {
  /**
   * Unique key passed in to reset tour
   */
  tourKey: number;
  /**
   * Whether or not we are running the tour
   */
  runTour: boolean;
}

interface AppTourContextInterface {
  /**
   * Unique key passed in to reset tour
   */
  tourKey: number;
  /**
  * Whether or not we are running the tour
  */
  runTour: boolean;
  /**
   * Callback to set a new tour object
   */
  incrementTourKey: () => void;
  /**
   * Callback to stop tour
   */
  stopTour: () => void;
  /**
   * What index the tour is on
   */
  stepIndex: number;
  /**
  * Callback to manually set the index
  */
  setStepIndex: (newStep: number) => void;
  /**
  * If the user has their wallet connected
  */
  connectedToSupportedNetwork: boolean;
}

const initialState: AppTourContextInterface = {
  tourKey: 0,
  runTour: false,
  incrementTourKey: () => {},
  stopTour: () => {},
  stepIndex: 0,
  setStepIndex: () => {},
  connectedToSupportedNetwork: false,
};

export const AppTourContext = createContext<AppTourContextInterface>(initialState);

interface AppTourProviderProps {
  /**
   * The children components of the provider
   */
  children: ReactNode;
  /**
   * Unique steps for the tour for each app
   */
  tourConfigSteps: TourConfigStep[];
}

export const AppTourProvider = ({ children, tourConfigSteps }: AppTourProviderProps) => {
  const [tourObject, setTourObject] = useState<TourObject>({ tourKey: 0, runTour: false });
  const [stepIndex, setStepIndex] = useState(0);
  const { connectedToSupportedNetwork } = useContext(WalletConnectionContext);

  const history = useHistory();

  const onHistoryPush = (path: string) => {
    history.push(path);
  };

  const incrementTourKey = () => {
    setTourObject({
      tourKey: tourObject.tourKey + 1,
      runTour: true,
    });
  };

  const stopTour = () => {
    setTourObject({
      tourKey: 0,
      runTour: false,
    });
  };

  return (
    <AppTourContext.Provider
      value={{
        tourKey: tourObject.tourKey,
        runTour: tourObject.runTour,
        incrementTourKey,
        stopTour,
        stepIndex,
        setStepIndex,
        connectedToSupportedNetwork,
      }}
    >
      { children }
      <AppTour
        tourConfigSteps={tourConfigSteps}
        tourKey={tourObject.tourKey}
        runTour={tourObject.runTour}
        onHistoryPush={onHistoryPush}
        onStopTour={stopTour}
        stepIndex={stepIndex}
        setStepIndex={setStepIndex}
        connectedToSupportedNetwork={connectedToSupportedNetwork}
      />
    </AppTourContext.Provider>
  );
};
