import { createTheme, Theme } from '@mui/material';
import { mooncakeDarkThemeOptions, mooncakeLightThemeOptions } from 'common-client';

const lightThemeOptions = {
  ...mooncakeLightThemeOptions,
  // Add overwrites here
};

export const lightTheme: Theme = createTheme(lightThemeOptions);

const darkThemeOptions = {
  ...mooncakeDarkThemeOptions,
  // Add overwrites here
};

export const darkTheme: Theme = createTheme(darkThemeOptions);
