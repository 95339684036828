import React from 'react';
import {
  Box, BoxProps, styled,
} from '@mui/material';

const BackgroundBox = styled(Box, { name: 'BackgroundBox' })<BoxProps>(({ theme }) => {
  const { background } = theme.palette;
  const backgroundGradient = background.gradient;
  return ({
    zIndex: -1,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: `linear-gradient(-180deg, ${
      backgroundGradient?.top || background.default
    } 0%, ${
      backgroundGradient?.bottom || background.default
    } 100%)`,
  });
});

// ToDo: @Fiddlekins make a story for this component
export function Background() {
  return (
    <BackgroundBox
      position="fixed"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    />
  );
}
