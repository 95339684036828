import React from 'react';
import { useTheme, Typography, styled } from '@mui/material';
import Joyride, {
  ACTIONS, CallBackProps, EVENTS, LIFECYCLE, STATUS,
} from 'react-joyride';
import { TourConfigStep } from 'contexts/AppTourContext';
import { FormatCurrency, getCurrencyAmount, USDT } from 'common-client';

interface AppTourProps {
  /**
   * Unique steps for the tour for each app
   */
  tourConfigSteps: TourConfigStep[];
  /**
   * Unique key passed in to reset tour
   */
  tourKey: number;
  /**
   * Whether or not we are running the tour
   */
  runTour: boolean;
  /**
   * Callback to change url route
   */
  onHistoryPush?: (path: string) => void;
  /**
   * Callback to stop tour
   */
  onStopTour: () => void;
  /**
   * What index the tour is on
   */
  stepIndex: number;
  /**
   * Callback to manually set the index
   */
  setStepIndex: (newStep: number) => void;
  /**
  * If the user has their wallet connected
  */
  connectedToSupportedNetwork: boolean;
}

const StyledTourHeading = styled(Typography)(() => ({
  textAlign: 'left',
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '28px',
}));

// TODO: move somewhere more appropriate
export const buttonZeroTourSteps: TourConfigStep[] = [
  {
    target: '#app-logo',
    content: (
      <div>
        <StyledTourHeading>
          Welcome to Buttonwood
        </StyledTourHeading>
        <Typography textAlign="left">
          Skip the tour or continue to learn about the decentralized bond market.
        </Typography>
      </div>
    ),
    placement: 'bottom-end',
    disableBeacon: true,
    path: '/',
    requiresWalletConnection: false,
    timeoutMs: 0,
  },
  {
    target: 'tbody > tr:first-of-type',
    content: (
      <div>
        <StyledTourHeading>
          Buy Bonds & Earn at Varying Rates
        </StyledTourHeading>
        <Typography textAlign="left">
          Each bond has different properties:
        </Typography>
        <ul style={{ padding: '0 0 0 22px' }}>
          <li>
            <Typography textAlign="left">
              <strong>Asset</strong>
              {' '}
              - The underlying asset the bond represents.
            </Typography>
          </li>
          <li>
            <Typography textAlign="left">
              <strong>Tranche</strong>
              {' '}
              - The type of tranche, A being the safest and Z being the riskiest.
            </Typography>
          </li>
          <li>
            <Typography textAlign="left">
              <strong>Maturity Date</strong>
              {' '}
              - The date after which tranche tokens can redeem their collateral.
            </Typography>
          </li>
          <li>
            <Typography textAlign="left">
              <strong>Collateralization</strong>
              {' '}
              - The percentage of collateral backing this bond.
            </Typography>
          </li>
          <li>
            <Typography textAlign="left">
              <strong>Discount</strong>
              {' '}
              - Lenders earn yield by purchasing discounted tranche tokens,
              {' '}
              which have an expected redeemable value of
              {' '}
              <FormatCurrency value={getCurrencyAmount(1, USDT)} />
            </Typography>
          </li>
        </ul>
      </div>
    ),
    path: '/buy',
    requiresWalletConnection: false,
    timeoutMs: 0,
  },
  {
    target: 'tbody > tr:first-of-type',
    content: (
      <div>
        <StyledTourHeading>
          Borrow Against Your Assets
        </StyledTourHeading>
        <ul style={{ padding: '0 0 0 22px' }}>
          <li>
            <Typography textAlign="left">
              <strong>Asset</strong>
              {' '}
              - The token being mortgaged.
            </Typography>
          </li>
          <li>
            <Typography textAlign="left">
              <strong>Maturity Date</strong>
              {' '}
              - The date after which tranche tokens can redeemed their collateral.
            </Typography>
          </li>
          <li>
            <Typography textAlign="left">
              <strong>Collateral to Debt Ratio (CDR)</strong>
              {' '}
              - The CDR at bond maturity determines the payout value for each tranche.
              A higher ratio is safer.
            </Typography>
          </li>
          <li>
            <Typography textAlign="left">
              <strong>Interest Rate</strong>
              {' '}
              - The projected percentage earned at maturity.
            </Typography>
            {' '}
          </li>
        </ul>
      </div>
    ),
    placement: 'bottom-start',
    path: '/sell',
    requiresWalletConnection: false,
    timeoutMs: 300,
  },
  {
    target: '.bond-position-container',
    content: (
      <div>
        <StyledTourHeading>
          See Bond Performance
        </StyledTourHeading>
        <br />
        <Typography textAlign="left">
          Sell your positions early or wait until bond maturity and redeem tokens.
        </Typography>
      </div>
    ),
    path: '/holdings',
    requiresWalletConnection: true,
    timeoutMs: 500,
  },
];

export const createSteps = (
  tourConfigSteps: TourConfigStep[],
  connectedToSupportedNetwork: boolean,
) => {
  if (!connectedToSupportedNetwork) {
    return tourConfigSteps.filter((step: TourConfigStep) => !step.requiresWalletConnection);
  }
  return tourConfigSteps;
};

export const AppTour = ({
  tourConfigSteps,
  tourKey,
  runTour = false,
  onHistoryPush,
  onStopTour,
  stepIndex,
  setStepIndex,
  connectedToSupportedNetwork,
}: AppTourProps) => {
  const theme = useTheme();

  const tourCallback = (data: CallBackProps) => {
    if (!onHistoryPush) return;

    // If target is not mounted, error, tour is completed, or tour is skipped, restart tour
    if (
      (data.type === EVENTS.TARGET_NOT_FOUND)
       || (data.type === EVENTS.ERROR)
       || (data.index + 1 === tourConfigSteps.length && data.lifecycle === LIFECYCLE.COMPLETE)
       || (data.status === STATUS.SKIPPED)
    ) {
      onHistoryPush('/');
      onStopTour();
      setStepIndex(0);
      return;
    }

    // Set to homepage if not already there
    if (data.index === 0 && data.lifecycle === LIFECYCLE.TOOLTIP) {
      onHistoryPush('/');
      setStepIndex(0);
    }

    if (data.action === ACTIONS.PREV && data.lifecycle === LIFECYCLE.COMPLETE) {
      const previousItem = tourConfigSteps[data.index - 1];
      if (previousItem && previousItem.path) onHistoryPush(previousItem.path);

      setTimeout(() => {
        setStepIndex(data.index - 1);
      }, previousItem.timeoutMs);
      return;
    }

    const nextItem = tourConfigSteps[data.index + 1];
    if (!nextItem || !nextItem.path) return;

    if (data.lifecycle === LIFECYCLE.COMPLETE) {
      if (!nextItem.path) {
        setStepIndex(data.index + 1);
        return;
      }

      onHistoryPush(nextItem.path);
      // Give time to render the page so that the target element is on the page
      setTimeout(() => {
        setStepIndex(data.index + 1);
      }, nextItem.timeoutMs);
    }
  };

  const compiledSteps = createSteps(tourConfigSteps, connectedToSupportedNetwork);
  return (
    <Joyride
      stepIndex={stepIndex}
      key={tourKey}
      run={runTour}
      steps={compiledSteps}
      callback={tourCallback}
      continuous
      showProgress
      showSkipButton
      styles={{
        options: {
          primaryColor: `${theme.palette.secondary.main}`,
          zIndex: 10000,
        },
        beacon: {
          display: 'none',
        },
        tooltip: {
          padding: '16px',
        },
        tooltipContent: {
          padding: '38px 0 0 0',
        },
        tooltipFooter: {
          marginTop: '12px',
        },
        overlay: {
          zIndex: 10000,
        },
      }}
    />
  );
};
