import React, { useEffect, useState } from 'react';
import {
  Box, BoxProps, Dialog, DialogProps, IconButton, styled, Typography, TypographyProps,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  FormatDate, getSecondsDiff, getMinutesDiff, getHoursDiff, getDaysDiff,
} from 'common-client';

interface NotificationBarProps {
  deployDate: Date
}

const StyledNotificationBar = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#237130',
  color: '#fff',
  cursor: 'pointer',
  fontSize: '12px',
  lineHeight: '20px',
  fontFamily: 'Inter',
  fontWeight: 600,
  position: 'absolute',
  top: 0,
  left: 0,
  textAlign: 'center',
  height: '40px',
  width: '100%',
  zIndex: 10000,
}));

const StyledDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: '40px',
    borderRadius: '6px',
    boxShadow: theme.shadows[8],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 24px',
      width: '100%',
      maxWidth: '375px',
      margin: 0,
      overflow: 'hidden',
    },
  },
}));

const CountdownContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  width: '100%',
}));

const TimeBox = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  maxWidth: '180px',
  height: '176px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: theme.shadows[8],
  borderRadius: '16px',
  [theme.breakpoints.down('sm')]: {
    maxWixth: '76px',
    height: '80px',
  },
}));

const StyledTime = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '56px',
  lineHeight: '72px',
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 400,
  },
}));

const StyledHeader = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '40px',
  lineHeight: '48px',
  fontFamily: 'Space Mono',
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
    lineHeight: '32px',
  },
}));

interface BondCountdownModalProps extends DialogProps {
  deployDate: Date
  onClose: () => void,
}

function StyledModal({ open, onClose, deployDate }: BondCountdownModalProps) {
  const [daysLeft, setDaysLeft] = useState(0);
  const [hoursLeft, setHoursLeft] = useState(0);
  const [minutesLeft, setMinutesLeft] = useState(0);
  const [secondsLeft, setSecondsLeft] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const now: Date = new Date();

      const secondsDiff = getSecondsDiff(now.getTime(), deployDate.getTime());
      const minutesDiff = getMinutesDiff(now.getTime(), deployDate.getTime());
      const hoursDiff = getHoursDiff(now.getTime(), deployDate.getTime());
      const daysDiff = getDaysDiff(now.getTime(), deployDate.getTime());

      setSecondsLeft(secondsDiff % 60);
      setMinutesLeft(minutesDiff % 60);
      setHoursLeft(hoursDiff % 24);
      setDaysLeft(daysDiff);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <StyledDialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <Box width="100%" display="flex" justifyContent="flex-end" mb="42px" mr="50px">
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Typography color="#237130" fontFamily="Space Mono" fontSize="14px" fontWeight="700" lineHeight="24px" letterSpacing="4px">BOND ISSUANCE</Typography>
      <StyledHeader align="center" my="16px">NEW BONDS DEPLOY IN</StyledHeader>
      <Typography fontFamily="Space Mono" fontSize="16px" fontWeight="700" lineHeight="24px" color="#A43A43" mb="24px">
        <FormatDate value={deployDate} display="medium" />
      </Typography>
      <CountdownContainer>
        <TimeBox>
          <StyledTime>{daysLeft}</StyledTime>
          <Typography>Days</Typography>
        </TimeBox>
        <TimeBox>
          <StyledTime>{hoursLeft}</StyledTime>
          <Typography>hours</Typography>
        </TimeBox>
        <TimeBox>
          <StyledTime>{minutesLeft}</StyledTime>
          <Typography>Minutes</Typography>
        </TimeBox>
        <TimeBox>
          <StyledTime>{secondsLeft}</StyledTime>
          <Typography>Seconds</Typography>
        </TimeBox>
      </CountdownContainer>
    </StyledDialog>
  );
}

export function NotificationBar({ deployDate }: NotificationBarProps) {
  const [showingModal, setShowingModal] = useState<boolean>(false);

  const handleClick = () => setShowingModal(true);

  const handleClose = () => setShowingModal(false);

  return (
    <div style={{ marginBottom: '40px' }}>
      <StyledNotificationBar onClick={handleClick}>
        <Typography fontFamily="Space Mono" fontSize="12px" fontWeight={700} lineHeight="20px">
          🎉 New Bonds Deploying on
          {' '}
          <FormatDate value={deployDate} display="medium" />
          {' '}
          🎉
        </Typography>
      </StyledNotificationBar>
      <StyledModal open={showingModal} onClose={handleClose} deployDate={deployDate} />
    </div>
  );
}
