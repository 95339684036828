import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/buttonwood-logo.svg';
import { Box, BoxProps, styled } from '@mui/material';

const LogoHolderBox = styled(Box, { name: 'LogoHolderBox' })<BoxProps>(({ theme }) => ({
  className: 'LogoHolderBox',
  my: '24px',
  width: '162px',
  height: '80px',
  '&:hover': {
    cursor: 'pointer',
  },
  [theme.breakpoints.down('sm')]: {
    my: '16px',
    width: '97.2px',
    height: '48px',
  },
}));

export function LogoWrapper() {
  const history = useHistory();
  const handleHomeClick = () => {
    history.push('/');
  };

  return (
    <LogoHolderBox onClick={handleHomeClick}>
      <Logo height="100%" width="100%" style={{ padding: '5px 0' }} />
    </LogoHolderBox>
  );
}
